import React from "react"
import Layout from "./layout/Layout"
import { IMetadata } from "../model/IMetadata"
import Section from "./section/Section"
import { AppContextProvider } from "./AppContext"
import { graphql } from "gatsby"
import { IStrapiImage } from "../model/IStrapiImage"
import * as styles from "./NewsPage.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { RichText } from "./RichText"
import { format, parseISO } from "date-fns"
import SEO from "./seo_analytics/SEO"

interface NewsProps {
  data: {
    strapi: {
      blogs: {
        blogTitle: string,
        blogContent: string,
        id: string,
        slug: string,
        published_at: string,
        blogImage: IStrapiImage,
        locale: string,
        metadata: IMetadata
      }[]
    }
  }
}

const NewsPage: React.FC<NewsProps> = (props) => {
  const blog = props.data.strapi.blogs[0]

  return (
    <AppContextProvider context={{
      currentLocale: blog.locale,
      currentSlug: blog.slug
    }}>
      <Layout pageSeo={blog.metadata}>
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P86CV2K" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
        <SEO {...blog.metadata}/>
        <Section className={styles.blogContainer}>
          <div style={{ paddingBottom : "50px"}}>
            <div className={styles.blogTitle}>{blog.blogTitle}</div>
            <div className={styles.blogContent}>{format(parseISO(blog.published_at), "dd MMMM, yyyy")}</div>
          </div>
          <GatsbyImage className={styles.blogImage} image={blog.blogImage.urlSharp.childImageSharp.gatsbyImageData} alt={blog.blogImage.alternativeText} />
          <div className={styles.blogContent}>
            <RichText text={blog.blogContent} />
          </div>
        </Section>
      </Layout>
    </AppContextProvider>
  )
}

export default NewsPage

export const query = graphql`
query($blogId: ID!, $locale: String!) {
  strapi {
    blogs(where: {id: $blogId, _locale: $locale}) {
        blogTitle
        blogContent
        id
        slug
        published_at
        locale
        metadata {
          ...metadataFields
        }
        blogImage {
          url
          urlSharp {
            childImageSharp {
              gatsbyImageData(width: 260, quality: 100)
            }
          }
        }
      }
    }
  }
`
